.placeholder .header .main-title-wrapper {
  background-color: transparent; }

.header {
  position: relative; }
  .header .main-title-wrapper {
    text-align: center;
    background: rgba(0, 0, 0, 0.5); }
  .header .main-title {
    color: #FF8300;
    text-shadow: 3px 3px 3px black;
    padding: 0;
    margin: 0;
    line-height: 0.7; }
  .header .image-carousel {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: -1; }
    .header .image-carousel .carousel {
      height: 100%; }
      .header .image-carousel .carousel .carousel-inner {
        height: 100%; }
        .header .image-carousel .carousel .carousel-inner .item {
          height: 100%; }
      .header .image-carousel .carousel .carousel-indicators {
        display: none; }
  .header .carousel-image {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center; }
  @media (max-width: 768px) {
    .header .main-title-wrapper {
      padding: 30px 0; }
    .header .main-title {
      font-size: 8rem; } }
  @media (min-width: 768px) {
    .header .main-title-wrapper {
      padding: 60px 0; }
    .header .main-title {
      font-size: 10rem; } }
  @media (min-width: 992px) {
    .header .main-title-wrapper {
      padding: 40px 0 110px; }
    .header .main-title {
      font-size: 12rem; } }

.brand-link {
  padding: 0;
  position: absolute;
  bottom: 0; }
  .brand-link img {
    height: 100%;
    width: 100%;
    display: block; }

.navbar {
  position: relative;
  background-color: #FF8300;
  border: none; }
  .navbar .navbar-toggle {
    border-color: #020202; }
    .navbar .navbar-toggle .icon-bar {
      background-color: #020202; }
    .navbar .navbar-toggle:focus, .navbar .navbar-toggle:hover {
      border-color: #FF8300;
      background-color: #020202; }
      .navbar .navbar-toggle:focus .icon-bar, .navbar .navbar-toggle:hover .icon-bar {
        background-color: #FF8300; }
  .navbar .navbar-collapse, .navbar .navbar-form {
    border-color: #D66F00; }
    .navbar .navbar-collapse .nav:first-child, .navbar .navbar-form .nav:first-child {
      margin-bottom: 0; }
    .navbar .navbar-collapse .nav:last-child, .navbar .navbar-form .nav:last-child {
      margin-top: 0; }
  .navbar .navbar-nav > li > a {
    color: #020202; }
    .navbar .navbar-nav > li > a:focus, .navbar .navbar-nav > li > a:hover {
      color: #020202;
      background-color: #D66F00; }
  .navbar .navbar-nav > .active > a {
    color: #FF8300;
    background-color: #020202; }
    .navbar .navbar-nav > .active > a:focus, .navbar .navbar-nav > .active > a:hover {
      color: #FF8300;
      background-color: #020202; }

@media (max-width: 768px) {
  .brand-link {
    width: 75px;
    height: 75px;
    left: calc(50% - (75px / 2)); }
  .navbar .navbar-header {
    position: relative; } }

@media (min-width: 768px) {
  .brand-link {
    width: 100px;
    height: 100px;
    left: calc(50% - (100px / 2)); } }

@media (min-width: 992px) {
  .brand-link {
    width: 150px;
    height: 150px;
    left: calc(50% - (150px / 2)); } }

.social-icons {
  padding: 4px 12px 4px 4px;
  margin: 8px 0;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 8px;
  height: 40px;
  display: inline-block; }
  .social-icons .icon {
    padding-left: 8px; }

.news .media:not(:last-child) {
  border-bottom: 1px solid #303030; }

@media (max-width: 768px) {
  .news .media-left {
    display: block;
    padding-bottom: 12px; } }

.meet-the-band .media:not(:last-child) {
  border-bottom: 1px solid #303030; }

@media (max-width: 768px) {
  .meet-the-band .media-left {
    display: block;
    padding-bottom: 12px; } }

.gallery .aspect-ratio-4-3 {
  width: 100%;
  padding-bottom: 75%; }

.gallery .gallery-image {
  background-position: center;
  background-size: cover;
  cursor: pointer; }

@media (max-width: 768px) {
  .gallery .gallery-grid {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    margin: -2px;
    width: calc(100% + 4px); }
  .gallery .gallery-image-wrapper {
    flex: 0 0 auto;
    box-sizing: border-box;
    width: calc((100% / 2) - 4px);
    margin: 2px; } }

@media (min-width: 768px) {
  .gallery .gallery-grid {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    margin: -3px;
    width: calc(100% + 6px); }
  .gallery .gallery-image-wrapper {
    flex: 0 0 auto;
    box-sizing: border-box;
    width: calc((100% / 3) - 6px);
    margin: 3px; } }

@media (min-width: 992px) {
  .gallery .gallery-grid {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    margin: -4px;
    width: calc(100% + 8px); }
  .gallery .gallery-image-wrapper {
    flex: 0 0 auto;
    box-sizing: border-box;
    width: calc((100% / 4) - 8px);
    margin: 4px; } }

@keyframes marquee1 {
  0% {
    transform: translateX(100%); }
  100% {
    transform: translateX(-100%); } }

@keyframes marquee2 {
  0% {
    transform: translateX(100%); }
  100% {
    transform: translateX(-200%); } }

@keyframes marquee3 {
  0% {
    transform: translateX(100%); }
  100% {
    transform: translateX(-300%); } }

@keyframes marquee4 {
  0% {
    transform: translateX(100%); }
  100% {
    transform: translateX(-400%); } }

@keyframes marquee5 {
  0% {
    transform: translateX(100%); }
  100% {
    transform: translateX(-500%); } }

.noselect {
  cursor: default;
  /* http://stackoverflow.com/a/4407335/4956731 */
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE/Edge */
  user-select: none;
  /* non-prefixed version, currently
                                  not supported by any browser */ }

.hidden {
  display: none !important; }

.audio_player {
  display: flex;
  flex-direction: row;
  height: 50px;
  background-color: #333;
  width: 100%;
  /* http://codepen.io/aralon/pen/NqGWXZ */ }
  .audio_player .spacer {
    width: 10px; }
  .audio_player .audio_progress_container {
    position: relative;
    height: 100%;
    flex-grow: 1; }
  .audio_player .audio_progress {
    background-color: #4a4a4a;
    height: 100%;
    width: 0;
    will-change: width; }
  .audio_player .audio_progress_overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between; }
  .audio_player .audio_info_marquee {
    overflow: hidden;
    /* to make room for .audio_time_progress */
    width: calc(100% - 120px);
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 10px; }
  .audio_player .audio_info {
    display: none;
    width: 100%;
    white-space: nowrap;
    word-break: break-all;
    margin: 0;
    font-size: inherit;
    color: #fff; }
  .audio_player .audio_time_progress {
    margin-right: 10px;
    align-self: center;
    color: #fff;
    font-size: inherit; }
  .audio_player .audio_button {
    padding-left: 4px;
    padding-right: 4px;
    margin-left: 2px;
    margin-right: 2px;
    cursor: pointer;
    align-self: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 50px; }
  .audio_player .play_pause_button .play_pause_inner {
    height: 16px;
    width: 16px;
    overflow: hidden;
    position: relative; }
  .audio_player .play_pause_button .left {
    height: 100%;
    float: left;
    background-color: #fff;
    width: 36%;
    transition: all 0.25s ease;
    overflow: hidden; }
  .audio_player .play_pause_button .triangle_1 {
    transform: translate(0, -100%); }
  .audio_player .play_pause_button .triangle_2 {
    transform: translate(0, 100%); }
  .audio_player .play_pause_button .triangle_1,
  .audio_player .play_pause_button .triangle_2 {
    position: absolute;
    top: 0;
    right: 0;
    background-color: transparent;
    width: 0;
    height: 0;
    border-right: 30px solid #333;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    transition: transform 0.25s ease; }
  .audio_player .play_pause_button .right {
    height: 100%;
    float: right;
    width: 36%;
    background-color: #fff;
    transition: all 0.25s ease; }
  .audio_player .play_pause_button.paused .left {
    width: 50%; }
  .audio_player .play_pause_button.paused .right {
    width: 50%; }
  .audio_player .play_pause_button.paused .triangle_1 {
    transform: translate(0, -50%); }
  .audio_player .play_pause_button.paused .triangle_2 {
    transform: translate(0, 50%); }
  .audio_player .skip_button.back {
    transform: rotate(180deg); }
  .audio_player .skip_button .skip_button_inner {
    display: flex;
    flex-direction: row; }
  .audio_player .skip_button .right_facing_triangle {
    width: 0;
    height: 0;
    border-left: 16px solid #fff;
    border-top: 16px solid transparent;
    border-bottom: 16px solid transparent; }
  @media screen and (min-width: 370px) {
    .audio_player .audio_info {
      display: initial;
      animation: marquee5 10s cubic-bezier(0, 0.23, 0.6, -0.09) infinite; } }
  @media screen and (min-width: 450px) {
    .audio_player .audio_info {
      animation: marquee4 10s cubic-bezier(0, 0.23, 0.7, -0.09) infinite; } }
  @media screen and (min-width: 550px) {
    .audio_player .audio_info {
      animation: marquee3 10s cubic-bezier(0, 0.23, 0.85, -0.09) infinite; } }
  @media screen and (min-width: 650px) {
    .audio_player .audio_info {
      animation: marquee2 10s cubic-bezier(0, 0.23, 1, -0.09) infinite; } }
  @media screen and (min-width: 720px) {
    .audio_player .audio_info {
      animation: initial; } }

.audio_player {
  position: fixed;
  bottom: 0; }
  .audio_player .play_pause_button .triangle_1, .audio_player .play_pause_button .triangle_2 {
    border-right-width: 16px;
    border-top-width: 8px;
    border-bottom-width: 8px; }
  .audio_player .skip_button .right_facing_triangle {
    border-left-width: 8px;
    border-top-width: 8px;
    border-bottom-width: 8px; }

body {
  background-color: #020202;
  background-image: linear-gradient(45deg, #020202, #303030, #020202);
  color: #020202;
  font-family: "Rokkitt", serif;
  font-size: 16px;
  padding-bottom: 50px; }

.content {
  background-color: #fAfAfA;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-bottom: 40px; }

.full-size-image {
  width: 100%; }

.placeholder {
  height: 100vh; }

.spinner {
  border: 8px solid #FF8300;
  border-top: 8px solid transparent;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin: auto; }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

* {
  box-sizing: border-box; }

@media (max-width: 768px) {
  .content {
    margin-right: 8px;
    margin-left: 8px; } }
